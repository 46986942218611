import { template as template_4180d26e47bb4d4f95b019dbd0c4eb73 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_4180d26e47bb4d4f95b019dbd0c4eb73(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
