import { template as template_1ae71e18875d4872936c6acc20b5f2f2 } from "@ember/template-compiler";
const SidebarSectionMessage = template_1ae71e18875d4872936c6acc20b5f2f2(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
