import { template as template_4456d07fcd60447b88bab442166b82e6 } from "@ember/template-compiler";
const FKControlMenuContainer = template_4456d07fcd60447b88bab442166b82e6(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
