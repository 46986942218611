import { template as template_f3bfebe7082a4afeb9bd1a9e2460e18b } from "@ember/template-compiler";
const EmptyState = template_f3bfebe7082a4afeb9bd1a9e2460e18b(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
