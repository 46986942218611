import { template as template_2f69c14f4b6a4ff39a6b138682b496c1 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_2f69c14f4b6a4ff39a6b138682b496c1(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
